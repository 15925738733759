export const modalItems = {
  course: {
    name: 'course',
    action: { delete: 'bubarkan', withdraw: 'keluar' },
  },
  participant: {
    name: 'student',
    action: { kick: 'mengeluarkan' },
  },
  enrolment: {
    name: 'enrolment',
    action: { approve: 'Terima', reject: 'Tolak' },
  },
  facePhoto: { name: 'photo', action: { delete: 'Hapus' } },
  attendance: { name: 'attendance', action: { delete: 'Delete' } },
};
