import { useQuery } from "@apollo/react-hooks";
import { notification } from "antd";
import React, { useContext, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { AuthContext } from "../context/auth";
import { CheckError } from "../utils/ErrorHandling";
import { FETCH_FACE_PHOTOS_COUNT_QUERY } from "../graphql/query";

function StudentRoute({ component: Component, ...rest }) {
  const { user } = useContext(AuthContext);

  //get uploaded photos query
  const facePhotosCountQuery = useQuery(FETCH_FACE_PHOTOS_COUNT_QUERY, {
    onError(err) {
      CheckError(err);
    },
  });

  //check amount of uploaded photo to notify student
  useEffect(() => {
    if (user && user.userLevel === 0)
      if (facePhotosCountQuery.data)
        if (facePhotosCountQuery.data.getFacePhotosCount < 2) {
          notification["info"]({
            message: (
              <strong>
                Mohon menambahkan setidaknya 2 foto<br />
                <br />
              </strong>
            ),
            description: `Foto yang sudah diupload: ${facePhotosCountQuery.data.getFacePhotosCount}`,
          });
          if (window.location.pathname != "/notification" && window.location.pathname != "/facegallery")
            window.location.href = "/facegallery";
        }
  }, [facePhotosCountQuery]);

  return (
    <Route
      {...rest}
      render={(props) =>
        user && user.userLevel === 0 ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
}

export default StudentRoute;
