import { Button, Col, Divider, Layout, Row, Typography } from 'antd';
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';
import React from 'react';
import { Link } from 'react-router-dom';
import { HeaderNavbar } from '../../../components/common/mainMenu';
import { Footer } from '../../../components/common/sharedLayout';

const { Content } = Layout;
const { Title } = Typography;

export default () => {
  return (
    <Layout className='home layout'>
      <HeaderNavbar />
      <Content>
        <Row align="middle">
          <Col style={{ top: '50px' }} span={24} align="middle">
            <Title level={3}>
              <Texty
                type="left"
                mode="random"
              >Selamat Datang di Aplikasi Presensi Siswa</Texty>
            </Title>
            <Texty
              type="left"
              mode="smooth"
              interval="20"
            >Pencatatan Kehadiran Siswa Otomatis menggunakan Pengenalan Wajah (AI)</Texty>

            <br />
            <Button type='primary' htmlType='submit'>
              <Link to='/signin'>MULAI</Link>
            </Button>
            <Divider />
            <div>
              Bingung? Silahkan baca <Link to='/userguidelines'> PANDUAN</Link>
            </div>

            <img src="https://webstoriess.enkosa.com/wp-content/uploads/2023/09/Download-Logo-SMK-Bisa-Hebat-PNG-1.png" width="200px"></img>
          </Col>
        </Row>
      </Content>
      <Footer />
    </Layout>
  );
};
