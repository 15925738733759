import { Button, Card, Col, Layout, Row, Avatar, Divider, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { HeaderNavbar } from '../../../components/common/mainMenu';
import { Footer } from '../../../components/common/sharedLayout';
import 'rc-texty/assets/index.css';
const { Content } = Layout;
const { Title } = Typography;
export default () => {
    return (
        <Layout className='home layout'>
            <HeaderNavbar />
            <Content>
                <Card>
                    <Row>
                    <Title level={2}>Panduan Pengguna</Title>
                    </Row>
                    <Row>
                        Pada aplikasi ini terdapat 2 Tipe Akun, yaitu "Siswa" dan "Guru"
                    </Row>

                </Card>
                <Divider />
                <Card>
  <Row>
    Petunjuk untuk Pengurus:
  </Row>
  <Row>
    1. Daftarkan akun.
  </Row>
  <Row>
    2. Buat Kelas.
  </Row>
  <Row>
    3. Berikan Kode unik kelas kepada murid Anda.
  </Row>
  <Row>
    4. Siswa perlu mengirim memasukkan kode kelas menggunakan akunnya.
  </Row>
  <Row>
    5. Anda tidak perlu mengunggah foto wajah murid, karena itu akan dilakukan oleh murid.
  </Row>
  <Row>
    6. Setelah semua murid terdaftar pada kelas, silahkan buat jadwal absensi kelas.
  </Row>
  <Row>
    7. Anda dapat melihat berapa banyak foto wajah yang diunggah oleh semua murid yang terdaftar.
  </Row>
  <Row>
    8. Pastikan bahwa murid mengunggah foto wajah mereka. jika tidak, maka siswa tidak dapat melakukan absensi.
  </Row>
  <Row>
    9. Hanya mereka yang mendaftar dalam kelas yang akan dihitung dalam dataset untuk klasifikasi wajah.
  </Row>
  <Row>
    10. Pada saat absensi kelas, mode kehadiran dan kamera.
  </Row>
  <Row>
    11. Mode presensi terbagi menjadi 2 yaitu F2F dan Remote.
  </Row>
  <Row>
    12. F2F (Face to Face) adalah jenis absensi tatap muka yang langsung dilakukan oleh Guru saat memulai kelas.
  </Row>
  <Row>
    13. Remote adalah jenis presensi pembelajaran secara daring / jarak jauh yang dilakukan oleh murid saat jam kelas dimulai.
  </Row>
  <Row>
    14. Data kehadiran keseluruhan ada di "Riwayat Kehadiran", atau Anda dapat melihat "Lihat Riwayat Kehadiran" setiap kelas.
  </Row>
  <Row>
    15. Anda dapat "Peringatkan" atau "Mengeluarkan" murid dari sebuah kelas.
  </Row>
  <Row>
    16. Laporan kehadiran siswa dan sesi absen kelas anda akan dikirim ke email anda pada tanggal 1 setiap bulannya.
  </Row>
</Card>
                <Divider />
                <Card>
                <Row>
    Petunjuk untuk Pengguna:
  </Row>
  <Row>
    1. Silahkan buat akun dengan mengklik Daftar.
  </Row>
  <Row>
    2. Isi bio data kamu (Email, Nama Depan, Nama Belakang, Nomor Induk Siswa, Password) dan pilih Otoritas Siswa.
  </Row>
  <Row>
    3. Kamu juga dapat mendaftar menggunakan akun Google kamu.
  </Row>
  <Row>
    4. Unggah foto kamu di menu "Foto Saya", sebaiknya mengupload minimal 2 foto wajah.
  </Row>
  <Row>
    5. Upload foto Satu per Satu dan pastikan foto wajah jelas dan terang.
  </Row>
  <Row>
    6. Setelah Upload foto. minta kode kelas kepada Guru Mata Pelajaran.
  </Row>
  <Row>
    7. Masukkan kode kelas pada menu "Kelas Saya",  Kamu akan otomatis terdaftar pada absensi kelas.
  </Row>
  <Row>
    8. Kamu hanya perlu menunjukkan wajah pada kamera absensi yang disediakan oleh Guru dengan Laptop / HP.
  </Row>
  <Row>
    9. Catatan kehadiran kamu dapat dilihat pada "Riwayat Kehadiran", atau kamu bisa lihat laporan kehadiran bulanan di Email kamu yang terdaftar pada aplikasi ini.
  </Row>
                </Card>
                <Divider />
                <Card>
                    <Row style={{ display: 'flex', justifyContent: 'flex-end'}}>
                        Apabila butuh bantuan lebih lanjut silahkan hubungi WhatsApp berikut : <a href="https://wa.me/62818180806" target="_blank"> 0818180806</a>
                    </Row>
                    <Row style={{ display: 'flex', justifyContent: 'flex-end'}}>
                        Made with &#128150; : <a href="https://al-bahza.my.id" target="_blank"> @Al Bahza'a</a>
                    </Row>
                </Card>
            </Content>
            <Footer />
        </Layout>
    )
}
