import { Layout } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

const { Footer } = Layout;
export default () => (
  <Footer style={{ textAlign: 'center' }}>
    <span>&copy; PT. Cloud Rise Technology {new Date().getFullYear()}</span>
    <span>&nbsp;&nbsp;&nbsp; <Link to="/privacypolicy">Kebijakan Privasi</Link></span>
    <span>&nbsp;&nbsp;&nbsp; <Link to="/termandcondition">Syarat & Ketentuan</Link></span>
    <span>&nbsp;&nbsp;&nbsp; <Link to="/userguidelines">Panduan Penggunaan</Link></span>
  </Footer>
);
