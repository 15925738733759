import { Card, Layout, Typography } from 'antd';
import React from 'react';
import { HeaderNavbar } from '../../../components/common/mainMenu';
import { Footer } from '../../../components/common/sharedLayout';
const { Content } = Layout;
const { Title } = Typography;

export default () => {
    return (
        <Layout className='home layout'>
            <HeaderNavbar />
            <Content>
            <Card>
  <Title level={2}>Kebijakan Privasi untuk MKAI</Title>

  <p>Di MKAI, yang dapat diakses dari https://MKAIDN.netlify.app, salah satu prioritas utama kami adalah privasi para pengunjung kami. Dokumen Kebijakan Privasi ini berisi jenis informasi yang dikumpulkan dan dicatat oleh MKAI serta bagaimana kami menggunakannya.</p>

  <p>Jika Anda memiliki pertanyaan tambahan atau memerlukan informasi lebih lanjut tentang Kebijakan Privasi kami, jangan ragu untuk menghubungi kami.</p>

  <p>Kebijakan Privasi ini hanya berlaku untuk kegiatan online kami dan berlaku bagi pengunjung situs web kami sehubungan dengan informasi yang mereka bagikan dan/atau koleksi di MKAI. Kebijakan ini tidak berlaku untuk informasi yang dikumpulkan secara offline atau melalui saluran lain selain situs web ini. Kebijakan Privasi kami dibuat dengan bantuan dari <a href="https://openai.com/"> Kecerdasan Buatan (AI)</a>.</p>

  <Title level={4}>Persetujuan</Title>

  <p>Dengan menggunakan situs web kami, Anda dengan ini memberikan persetujuan terhadap Kebijakan Privasi kami dan menyetujui ketentuannya.</p>

  <Title level={4}>Informasi yang kami kumpulkan</Title>

  <p>Informasi pribadi yang diminta kepada Anda, dan alasan mengapa diminta, akan dijelaskan dengan jelas pada saat kami meminta Anda untuk memberikan informasi pribadi Anda.</p>
  <p>Jika Anda menghubungi kami secara langsung, kami mungkin menerima informasi tambahan tentang Anda seperti nama, alamat email, nomor telepon, isi pesan dan/atau lampiran yang Anda kirimkan kepada kami, dan informasi lain yang mungkin Anda berikan.</p>
  <p>Saat Anda mendaftar untuk Akun, kami mungkin meminta informasi kontak Anda, termasuk hal-hal seperti nama, nama perusahaan, alamat, alamat email, dan nomor telepon.</p>

  <Title level={4}>Bagaimana kami menggunakan informasi Anda</Title>

  <p>Kami menggunakan informasi yang kami kumpulkan dengan berbagai cara, termasuk untuk:</p>

  <ul>
    <li>Menyediakan, mengoperasikan, dan memelihara situs web kami</li>
    <li>Meningkatkan, mempersonalisasi, dan memperluas situs web kami</li>
    <li>Mengerti dan menganalisis bagaimana Anda menggunakan situs web kami</li>
    <li>Mengembangkan produk, layanan, fitur, dan fungsionalitas baru</li>
    <li>Berhubungan dengan Anda, baik langsung maupun melalui salah satu mitra kami, termasuk untuk layanan pelanggan, memberi Anda pembaruan dan informasi lain yang berkaitan dengan situs web, dan untuk tujuan pemasaran dan promosi</li>
    <li>Mengirimkan email kepada Anda</li>
    <li>Mencari dan mencegah penipuan</li>
  </ul>

  <Title level={4}>Kebijakan Privasi Data Wajah</Title>
  <p>Kami akan menggunakan data wajah Anda untuk mengekstrak fitur-fiturnya guna melakukan analisis wajah.</p>
  <p>Kami akan menggunakan data center yang disediakan oleh PT. Cloud Rise Technology untuk menyimpan data wajah dan mengambil URL CDN untuk tujuan tampilan.</p>
  <p>Kami tidak menjual, mengiklankan, atau mempublikasikan data wajah Anda kepada pihak ketiga.</p>
  <p>Data wajah Anda dilindungi dan tidak akan digunakan untuk tindakan ilegal.</p>
  <p>Dengan mengunggah foto wajah Anda di situs web kami, Anda menyetujui kami untuk menggunakan data wajah Anda dan menyimpannya di data center perusahaan kami.</p>
  
  <Title level={4}>File Log</Title>

  <p>MKAI mengikuti prosedur standar penggunaan file log. File-file ini mencatat kunjungan pengunjung ketika mereka mengunjungi situs web. Semua perusahaan hosting melakukannya dan ini merupakan bagian dari analitik layanan hosting. Informasi yang dikumpulkan melalui file log meliputi alamat protokol internet (IP), jenis peramban, Penyedia Layanan Internet (ISP), tanggal dan waktu, halaman rujukan/keluar, dan mungkin jumlah klik. Informasi ini tidak terkait dengan informasi yang dapat diidentifikasi secara pribadi. Tujuan dari informasi ini adalah untuk menganalisis tren, mengelola situs, melacak pergerakan pengguna di situs web, dan mengumpulkan informasi demografis.</p>

  <Title level={4}>Kebijakan Privasi Mitra Periklanan</Title>

  <p>Anda dapat melihat daftar ini untuk menemukan Kebijakan Privasi untuk masing-masing mitra periklanan MKAI.</p>

  <p>Server iklan pihak ketiga atau jaringan iklan menggunakan teknologi seperti cookie, JavaScript, atau Web Beacon yang digunakan dalam iklan dan tautan mereka masing-masing yang muncul di MKAI, yang dikirim langsung ke peramban pengguna. Mereka secara otomatis menerima alamat IP Anda ketika hal ini terjadi. Teknologi ini digunakan untuk mengukur efektivitas kampanye periklanan mereka dan/atau untuk mempersonalisasi konten iklan yang Anda lihat di situs web yang Anda kunjungi.</p>

  <p>Perlu diketahui bahwa MKAI tidak memiliki akses atau kontrol atas cookie yang digunakan oleh pengiklan pihak ketiga tersebut.</p>

  <Title level={4}>Kebijakan Privasi Pihak Ketiga</Title>

  <p>Kebijakan Privasi MKAI tidak berlaku untuk pengiklan atau situs web lainnya. Oleh karena itu, kami menyarankan Anda untuk berkonsultasi dengan Kebijakan Privasi masing-masing server iklan pihak ketiga untuk informasi lebih detail. Hal tersebut dapat mencakup praktik-praktik mereka dan petunjuk tentang cara mengopt-out dari beberapa pilihan tertentu.</p>

  <p>Anda dapat memilih untuk menonaktifkan cookie melalui opsi peramban individu Anda. Untuk informasi lebih rinci tentang pengelolaan cookie dengan peramban web tertentu, Anda dapat menemukannya di situs web masing-masing peramban.</p>

  <Title level={4}>Hak Privasi CCPA (Jangan Jual Informasi Pribadi Saya)</Title>

  <p>Di bawah CCPA, di antara hak-hak lainnya, konsumen California memiliki hak untuk:</p>
  <p>Mengajukan permintaan kepada bisnis yang mengumpulkan data pribadi konsumen untuk mengungkapkan kategori dan informasi pribadi spesifik yang dikumpulkan bisnis tentang konsumen.</p>
  <p>Mengajukan permintaan kepada bisnis yang mengumpulkan data pribadi konsumen untuk menghapus data pribadi apa pun yang dikumpulkan bisnis tentang konsumen.</p>
  <p>Mengajukan permintaan kepada bisnis yang menjual data pribadi konsumen untuk tidak menjual data pribadi konsumen tersebut.</p>
  <p>Jika Anda membuat permintaan, kami memiliki satu bulan untuk merespons Anda. Jika Anda ingin mengajukan salah satu dari hak-hak ini, silakan hubungi kami.</p>

  <Title level={4}>Hak Perlindungan Data GDPR</Title>

  <p>Kami ingin memastikan Anda sepenuhnya mengetahui semua hak perlindungan data Anda. Setiap pengguna berhak atas hal-hal berikut:</p>
  <p>Hak akses – Anda berhak untuk meminta salinan data pribadi Anda. Kami dapat mengenakan biaya kecil untuk layanan ini.</p>
  <p>Hak koreksi – Anda berhak untuk meminta kami memperbaiki informasi yang Anda anggap tidak akurat. Anda juga berhak untuk meminta kami melengkapi informasi yang Anda anggap belum lengkap.</p>
  <p>Hak penghapusan – Anda berhak untuk meminta kami menghapus data pribadi Anda, dengan beberapa kondisi tertentu.</p>
  <p>Hak pembatasan pemrosesan – Anda berhak untuk meminta kami membatasi pemrosesan data pribadi Anda, dengan beberapa kondisi tertentu.</p>
  <p>Hak keberatan terhadap pemrosesan – Anda berhak untuk memprotes pemrosesan data pribadi Anda oleh kami, dengan beberapa kondisi tertentu.</p>
  <p>Hak portabilitas data – Anda berhak untuk meminta kami mentransfer data yang kami kumpulkan ke organisasi lain, atau langsung kepada Anda, dengan beberapa kondisi tertentu.</p>
  <p>Jika Anda membuat permintaan, kami memiliki satu bulan untuk merespons Anda. Jika Anda ingin menggunakan salah satu hak-hak ini, silakan hubungi kami.</p>

  <Title level={4}>Informasi untuk Anak-anak</Title>

  <p>Bagian lain dari prioritas kami adalah menambahkan perlindungan bagi anak-anak saat menggunakan internet. Kami mendorong orang tua dan wali untuk mengawasi, berpartisipasi, dan/atau memantau serta membimbing aktivitas online mereka.</p>

  <p>MKAI tidak secara sadar mengumpulkan Informasi Identifikasi Pribadi apa pun dari anak di bawah usia 13 tahun. Jika Anda percaya bahwa anak Anda memberikan informasi semacam itu di situs web kami, kami sangat menyarankan Anda untuk segera menghubungi kami dan kami akan melakukan upaya terbaik kami untuk segera menghapus informasi tersebut dari catatan kami.</p>

  <Title level={4}>Persetujuan</Title>

  <p>Dengan menggunakan situs web kami, Anda dengan ini memberikan persetujuan terhadap Kebijakan Privasi kami dan menyetujui ketentuannya.</p>

  <Title level={4}>Pembaruan Kebijakan Privasi</Title>

  <p>Kebijakan Privasi ini akan diperbarui secara berkala, dan perubahan akan diposting di halaman ini. Jika ada perubahan material dalam Kebijakan Privasi kami, kami akan memberi tahu Anda melalui email atau pemberitahuan pada situs web kami sebelum perubahan tersebut berlaku. Oleh karena itu, kami menyarankan Anda untuk memeriksa halaman ini secara berkala untuk pembaruan terbaru.</p>

  <Title level={4}>Hubungi Kami</Title>

  <p>Jika Anda memiliki pertanyaan tambahan atau memerlukan informasi lebih lanjut tentang Kebijakan Privasi kami, jangan ragu untuk menghubungi kami melalui email di mkai@example.com.</p>
</Card>

            </Content>
            <Footer />
        </Layout>
    );
}
