// index.js

import { Avatar, Layout, Menu } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { APP_LOGO_URL } from '../../../assets'; 
import './HeaderNavbar.css';

const { Header } = Layout;

export default () => {  

  const pathname = window.location.pathname;
  const path = pathname.substr(1);

  return (
    <div className='headerNavbar'>
      <Header>
        <Link to='/'>
          <div className='headerNavbar__logo'>
            <Avatar
              shape='square'
              className='avatar'
              size='large'
              alt='logo'
              src={APP_LOGO_URL.link}
              onError={(err) => {
                console.log(err);
              }}
            />
            <img src="/img/MKAI.png" alt="MKAI" className='tagline'></img>
          </div>
        </Link>
        <div className='headerNavbar__menu'>
          <Menu theme='dark' mode='horizontal' defaultSelectedKeys={[path]}>
            <Menu.Item key='signin'>
              <Link to='/signin'>Masuk</Link>
            </Menu.Item>
            <Menu.Item key='signup'>
              <Link to='/signup'>Daftar</Link>
            </Menu.Item> 
          </Menu>
        </div>
      </Header>
    </div>
  );
};
